import { mapGetters } from "vuex";
export default {
  data() {
    return {
      iFrame:'',
      Sing_in_Url:'',
      private_iFrame:'',
      public_Iframe:'',
      signIn :false,
    };
  },
  async mounted() {
  },
  computed: { ...mapGetters("sso",["getAuthenticatedSSOToken"]),
  ...mapGetters("company", ["getContactLoginSlug","getUpdateCompany","getCompanyDetails"]),
  ...mapGetters("iFrame",["getIframeUrlInfo"]),
},
  methods: {
    async getNavigationQuery(query) {
      let q = {
        key: Math.floor(Math.random() * 100000),
      };
      query.group ? (q.group = query.group) : "";
      query.groups ? (q.groups = query.groups) : "";
      query.t ? (q.t = query.t) : "";
      query.routeType ? (q.routeType = query.routeType) : "";
      query.filter ? (q.filter = query.filter) : "";
      query.customization ? (q.customization = query.customization) : "",
      query.fromCustom ? (q.fromCustom = query.fromCustom) : "";
      query.menu_id ? (q.menu_id = query.menu_id) : "";
      query.dataId ? (q.dataId = query.dataId) : "";
      query.stepId ? (q.stepId = query.stepId) : "";
      query.layout ? (q.layout = query.layout) : "";
      query.viewType ? (q.viewType = query.viewType) : "";
      query.activeTab ? (q.activeTab = query.activeTab) : "";
      query.formbuilder_data_id ? (q.formbuilder_data_id = query.formbuilder_data_id) : "";
      query.redirect && query.redirect != 'undefined' ? (q.redirect = query.redirect ) : "";
      query.addNew ? (q.addNew = query.addNew) : ""; 
      query.isMenu == 'true' ? (q.isMenu = 'true') : "";  
      return q;
    },
    urlBuilder(query) {
      let url = "";
      url = url + `key=${Math.floor(Math.random() * 100000)}`;
      if (query.group) url = url + `&group=${query.group}`;
      if (query.groups) url = url + `&groups=${query.groups}`;
      if (query.t) url = url + `&t=${query.t}`;
      if (query.routeType) url = url + `&routeType=${query.routeType}`;
      if (query.filter) url = url + `&filter=${query.filter}`;
      if (query.fromCustom) url = url + `&fromCustom=${query.fromCustom}`;
      if (query.menu_id) url = url + `&menu_id=${query.menu_id}`;
      if (query.dataId) url = url + `&dataId=${query.dataId}`;
      if (query.stepId) url = url + `&stepId=${query.stepId}`;
      if (query.layout) url = url + `&layout=${query.layout}`;
      if (query.viewType) url = url + `&viewType=${query.viewType}`;
      if (query.activeTab) url = url + `&activeTab=${query.activeTab}`;
      if(query.tab) url = url + `&tab=${query.tab}`;
      if(query.customization) url = url + `&customization=${query.customization}`;
      if(query.subTab) url = url + `&subTab=${query.subTab}`
      if(query.subType) url = url + `&subType=${query.subType}`
      if(query.tabOrd) url = url + `&tabOrd=${query.tabOrd}`
      if(query.addNew) url = url + `&addNew=${query.addNew}`
      if(query.redirect) url= url + `&redirect=${query.redirect}`
      return url;
    },

    async isIframe(){
      const currentPath = this.$route.path;
      if (this.$route.params.ssoAuth) {
        this.iFrame = currentPath
        let params = {
          id: this.$route.params.ssoAuth,
        }
        await this.$store.dispatch("iFrame/fetchIframeUrlTypes", params);
      }
   },
     checkUrlType() {
       if (this.getIframeUrlInfo?.url_type == 'PRIVATE') {
         return 'private';
       }
       else if (this.getIframeUrlInfo?.url_type == 'PUBLIC') {
         return 'public';
       }
       else {
         return 'SignIn'
       }
     }
  },
};
